<template>
  <div class="pro_box">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic14.png" />Country Ratings</div>
      <div class="btn_export flex_center" @click="viewHistory"><img src="../../assets/img/detail/btn_ic4.png" />View History</div>
    </div>
    <div class="right_cont_box">
      <div class="group profile">
        <div class="item">
          <label class="lab">Competitiveness Index: </label>
          <div class="txt">{{ objRate.competitivenessIndex ? objRate.competitivenessIndex : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Competitiveness Rank: </label>
          <div class="txt">{{ objRate.competitivenessRank ? objRate.competitivenessRank : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Ease of Doing Business: </label>
          <div class="txt">{{ objRate.easeOfDoingBusiness ? objRate.easeOfDoingBusiness : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Legal Environment: </label>
          <div class="txt">{{ objRate.legalEnvironment ? objRate.legalEnvironment : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Business Confidence: </label>
          <div class="txt">{{ objRate.businessConfidence ? objRate.businessConfidence : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Consumer Confidence: </label>
          <div class="txt">{{ objRate.consumerConfidence ? objRate.consumerConfidence : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Business Climate Assessment: </label>
          <div class="txt">{{ objRate.businessClimate ? objRate.businessClimate : '-' }}</div>
        </div>
      </div>
    </div>

    <div class="rate_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic14.png" />Country Ratings</div>
      <ul class="rate flex_box">
        <li>
          <div class="rate_title">S&P</div>
          <div class="rate_txt">{{ objRate.sp }}</div>
        </li>
        <li>
          <div class="rate_title">Moody's</div>
          <div class="rate_txt">{{ objRate.moody }}</div>
        </li>
        <li>
          <div class="rate_title">Fitch</div>
          <div class="rate_txt">{{ objRate.fitch }}</div>
        </li>
        <li>
          <div class="rate_title">DBRS</div>
          <div class="rate_txt">{{ objRate.dbrs }}</div>
        </li>
        <li>
          <div class="rate_title">Allianz</div>
          <div class="rate_txt">{{ objRate.allianzTrade }}</div>
        </li>
        <li>
          <div class="rate_title">Coface</div>
          <div class="rate_txt">{{ objRate.coface }}</div>
        </li>
        <li>
          <div class="rate_title">Atradius</div>
          <div class="rate_txt">{{ objRate.atradius }}</div>
        </li>
        <li>
          <div class="rate_title">OECD</div>
          <div class="rate_txt">{{ objRate.oecd }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'
export default {
  data() {
    return {
      objRate: {
        competitivenessIndex: '',
        competitivenessRank: '',
        easeOfDoingBusiness: '',
        legalEnvironment: '',
        businessConfidence: '',
        consumerConfidence: '',
        businessClimate: '',
        sp: '',
        moody: '',
        fitch: '',
        dbrs: '',
        allianzTrade: '',
        coface: '',
        atradius: '',
        oecd: '',
      },
      companyCountry:'',
    }
  },
  computed: {},
  watch: {},

  created() {
    //types: CE CP CB CR CT 首字母缩写枚举
    //countryCodes: US CN
    //years: String 类型

    this.getData()
  },
  methods: {
    getData() {
      this.companyCountry = this.$route.query.companyCountry

      const params = {
        types: ['CR'],
        countryCodes: [this.companyCountry],
        // years: [''],
      }
      searchCountryInfo(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          console.log('result- rate', data.data)
          let obj = data.data.countryRatingsList ? data.data.countryRatingsList[0] : ''
          this.objRate = {
            competitivenessIndex: obj.competitivenessIndex,
            competitivenessRank: obj.competitivenessRank,
            easeOfDoingBusiness: obj.easeOfDoingBusiness,
            legalEnvironment: obj.legalEnvironment,
            businessConfidence: obj.businessConfidence,
            consumerConfidence: obj.consumerConfidence,
            businessClimate: obj.businessClimate,
            sp: obj.sp,
            moody: obj.moody,
            fitch: obj.fitch,
            dbrs: obj.dbrs,
            allianzTrade: obj.allianzTrade,
            coface: obj.coface,
            atradius: obj.atradius,
            oecd: obj.oecd,
          }
        }
      })
    },
    //跳转
    viewHistory() {
      this.$router.push({ path: '/country/viewhistory', query: { companyCountry: this.companyCountry } })
    },
  },
}
</script>
<style scoped>
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pro_box {
  margin-top: 22px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 232px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
.rate_box .title_txt {
  margin-bottom: 34px;
  padding: 0 20px;
}
.rate_box {
  height: 146px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 1px rgba(10, 33, 57, 0.14);
  border-radius: 8px;
  padding: 20px 0;
  margin-top: 20px;
}
.rate {
  padding: 0 3px;
}
.rate li {
  position: relative;
  width: 121px;
}
.rate li:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 4px;
  width: 1px;
  height: 37px;
  background-color: #e7eaed;
}
.rate li .rate_title {
  font-size: 12px;
  color: #8497ab;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
}
.rate li .rate_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
  color: #1290c9;
  line-height: 24px;
  text-align: center;
}
@media (max-width: 821px) {
.rate li{
  width: 30% !important;
      margin: 10px 0px;
}
.flex_box {
  flex-wrap: wrap;
  justify-content: space-between;
}
.rate_box{
  height: auto !important;
}
}


</style>
